<template>
  <div :style="{ backgroundImage: `url(${computedBackgroundImage})` }" class="pt-[80px] md:pt-[120px] px-[20px] md:px-[80px] bg-banner min-h-[700px] w-full grid lg:grid-cols-2 grid-cols-1">
    <div class="half-circle-desktop hidden lg:flex" />
    <div class="half-circle-mobile md:hidden" />
    <div :class="`lg:mb-[80px] ${bannerContainerClass}`">
      <div v-if="title || subTitle" class="lg:w-full w-11/12 mx-auto">
        <div v-if="title" class="text-white lg:text-7xl text-3xl font-medium font-['Lato'] capitalize lg:text-left text-center leading-normal">
          {{ title }}
        </div>
        <div v-if="subTitle" class="text-white lg:text-xl text-lg font-medium font-['Montserrat'] mt-[20px] mb-[55px] lg:text-left text-center">
          {{ subTitle }}
        </div>
      </div>
      <div v-if="bannerFeatures" class="grid lg:grid-cols-2 grid-cols-1 lg:gap-[42px] gap-[22px]">
        <div v-for="(feature, index) in bannerFeatures" :key="index" class="feature-item flex items-center gap-[25px] flex-1 min-w-[50%]">
          <CheckCircleIcon class="h-8 w-8 text-white flex-shrink-0" />
          <div class="text-white lg:text-xl text-lg font-medium font-['Montserrat']">
            {{ feature }}
          </div>
        </div>
      </div>
      <div v-if="ctaText" class="flex lg:justify-normal justify-center mt-16 lg:mb-0 mb-16">
        <CommonButtonsEstimate :text="ctaText" :icon="true" button-classes="bg-[#E54825] cursor-pointer" />
      </div>
    </div>
    <div class="m-auto lg:justify-end justify-center">
      <CommonBoxWithLinkButton v-if="showThisLinkInsteadOfForm" :show-this-link-instead-of-form="showThisLinkInsteadOfForm" :icon="false" />
      <CommonFormsEstimate v-else />
    </div>
  </div>
</template>

<script setup>
import { CheckCircleIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  backgroundImage: {
    type: String,
    required: false,
    default: () => null
  },
  title: {
    type: String,
    required: true
  },
  subTitle: {
    type: String,
    required: true
  },
  ctaText: {
    type: String,
    required: false,
    default: () => ''
  },
  bannerFeatures: {
    type: Array,
    required: false,
    default: () => null
  },
  bannerContainerClass: {
    type: String,
    required: false,
    default: () => 'flex flex-col z-0 lg:items-start items-center'
  },
  showThisLinkInsteadOfForm: {
    type: String,
    default: null,
    required: false
  }
})

const computedBackgroundImage = computed(() => {
  if (props.backgroundImage) {
    return props.backgroundImage
  }

  const screenWidth = useWindowSize().width.value
  let imageUrl = '/banner/banner-default.webp'

  if (screenWidth <= 768) {
    imageUrl = '/banner/banner-small.webp'
  } else if (screenWidth <= 1200) {
    imageUrl = '/banner/banner-medium.webp'
  }

  return imageUrl
})

</script>

<style>
  .bg-banner {
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
  }

  .bg-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
  }

  .half-circle-desktop {
    position: absolute;
    top: -32em;
    right: -15em;
    width: 70em;
    height: 70em;
    background-color: #e54825cc;
    border-radius: 35em;
  }

  .half-circle-mobile {
    position: absolute;
    bottom: -25em;
    right: -23em;
    width: 70em;
    height: 70em;
    background-color: #e54825cc;
    border-radius: 35em;
  }

  .form-input-style input, .form-input-style textarea, .form-input-style select {
  appearance: none;
  display: block;
  width: 100%;
  background-color: #CFCFCF; /* bg-gray-200 */
  color: #4a5568; /* text-gray-700 */
  border: 1px solid #cbd5e0; /* border-gray-300, assuming a light gray border */
  border-radius: 0.375rem; /* rounded */
  padding: 1em; /* py-3 px-4 */
  margin-bottom: 0.75rem; /* mb-3 */
  line-height: 1.5; /* leading-tight */
  outline: none;
  transition: background-color 0.2s ease-in-out;
  font-size: 18px;
}

select:focus, textarea:focus, input:focus {
  border: none;
}
</style>
