<template>
  <div id="estimate-form" :class="`py-[30px] md:px-[30px] h-auto opacity-95 bg-white rounded-[16.54px] backdrop-blur-[135.65px] max-w-xl md:mx-[20px] mb-[80px]`">
    <div class="text-[#151313] lg:text-3xl text-2xl lg:text-left text-center font-montserrat font-medium leading-tight break-words">
      {{ props.title }}
    </div>
    <div class="w-full lg:my-[15px] my-[10px]">
      <div class="flex justify-center h-auto">
        <CommonButtonsEstimate :text="buttonText" :icon="false" :link="props.showThisLinkInsteadOfForm" :new-tab="true" class="py-[8px] w-[100%] mt-[15px]" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  title: {
    type: String,
    required: false,
    default: () => 'Learn About Financing Options and Apply Today'
  },
  buttonText: {
    type: String,
    required: false,
    default: () => 'Get Pricing'
  },
  showThisLinkInsteadOfForm: {
    type: String,
    required: false,
    default: () => '#'
  }
})
</script>

<style>

</style>
